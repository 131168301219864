/**
 * Appointment Confirmation page - Demo AU Next Practice
 */
/* Import section */
import * as React from "react"
import { graphql } from "gatsby"
import Geturlparameter from "../components/geturlparameter"
import Layoutwithoutnav from "../components/layoutwithoutnav"
import Seo from "../components/seo"
import "../styles/pages/appointment.scss"

const AppointmentconfirmationPage = ({ data, search }) => {
  const { result } = search
  //Const data


  return(
    <Layoutwithoutnav>
      <Seo
        title="Appointment Confirmation"
        description="Appointment Confirmation page - test Demo AU"
         />
      <section className="section-hero-appointment">
        <div className="box-hero-appointment">
          <h1>Appointment Information</h1>
        </div>
      </section>
      <section className="section-content-appoitment">
        <div className="wrap-centercontent">
          { result === "SUCCESSFUL_CONFIRMATION"
            ? <div className="centercontent general">
                <h2>Your appointment is now confirmed.</h2>
                <p>If you have any question, or want to make changes to your appointment, communicate with your practice by phone.</p>
              </div>
            :  result === "UNSUCCESSFUL"
              ?  <div className="centercontent general">
                  <h2>Unfortunately, we cannot complete your request.</h2>
                  <p>If you have any question, or want to make changes to your appointment, communicate with your practice by phone.</p>
                </div>
              :  result === "SUCCESSFUL_CANCELATION"
                ?  <div className="centercontent general">
                    <h2>Your appointment is now cancelled.</h2>
                    <p>If you have any question, or want to make changes to your appointment, communicate with your practice by phone.</p>
                  </div>
                : <div className="centercontent general">
                    <h2>Sorry, this request doesn't exist.</h2>
                    <p>If you have any question, or want to make changes to your appointment, communicate with your practice by phone.</p>
                  </div>
            }
        </div>
      </section>
    </Layoutwithoutnav>
  )
}

/* Query page */
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Geturlparameter(AppointmentconfirmationPage)
