/**
 * Const element
 * Get URL parameter component
 * Wrap component to extract GET value of param in URL
 */
 /* Import section */
 import React from "react"
 import { Location } from "@reach/router"
 import queryString from "query-string"

 /* Declaration function */
 const Geturlparameter = ComponentToWrap => props => (
   <Location>
     {({ location, navigate }) => (
       <ComponentToWrap
         {...props}
         location={location}
         navigate={navigate}
         search={location.search ? queryString.parse(location.search) : {}}
       />
     )}
   </Location>
 )

 /* Export function */
 export default Geturlparameter
