/**
 * Const with params element
 * SEO component
 * Display SEO information from YOAST with HELMET
 */

 /* Import */
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"
import {useI18next, I18nextContext} from "gatsby-plugin-react-i18next"

/* Declaration */
const Seo = ({ title, description, tLinkSlug, classHtml, slickslider, classbody  }) => {
  const { site, defaultImage, defaultLogo} = useStaticQuery(query)
  const { pathname } = useLocation()
  const { originalPath } = useI18next()

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    siteLanguage,
  } = site.siteMetadata

  const ogImage = defaultImage.childImageSharp.gatsbyImageData.images.fallback.src
  const imageSeo = siteUrl+ogImage

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: imageSeo,
    url: `${siteUrl}${pathname}`,
    language: siteLanguage
  }

  const context = React.useContext(I18nextContext)
  var cLang = context.language
  var tLang = (cLang === "en") ? "fr":"en"
  var cSlug = null
  var tSlug = null

  if(tLinkSlug === null){
    cSlug = (cLang === "en") ? siteUrl+originalPath:siteUrl+"/fr"+originalPath
    tSlug = (cLang === "en") ? siteUrl+"/fr"+originalPath:siteUrl+originalPath
  }else {
    cSlug = (cLang === "en") ? siteUrl+originalPath:siteUrl+"/fr"+originalPath
    tSlug = (cLang === "en") ? siteUrl+"/fr"+tLinkSlug:siteUrl+tLinkSlug
  }

  //DataStructured - Logo
  let schemaLogo = null
  schemaLogo = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://myca.com",
    "logo": siteUrl+defaultLogo.childImageSharp.gatsbyImageData.images.fallback.src
  }

  return (
    <Helmet title={seo.title} >
      <html lang={siteLanguage} class={classHtml}/>

      <link rel="alternate" href={cSlug} hreflang={cLang} />
      <link rel="alternate" href={tSlug} hreflang={tLang} />
      <link rel="alternate" href={cSlug} hreflang="x-default" />

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:type" content="website"></meta>

      <meta property="og:url" content={seo.url}/>
      <meta property="og:title" content={seo.title}/>
      <meta property="og:description" content={seo.description}/>
      <meta property="og:image" content={seo.image}/>
      <meta property="og:locale" content={siteLanguage} />

      <meta name="twitter:card" content="summary_large_image"/>
      <meta property="twitter:domain" content="myca.com" />
      <meta property="twitter:url" content={seo.url}/>
      <meta name="twitter:title" content={seo.title}/>
      <meta name="twitter:description" content={seo.description}/>
      <meta name="twitter:image" content={seo.image} />

      <meta name="msvalidate.01" content="185BB0C41DC940FF5C70D102F8EA873C" />

      <script type="application/ld+json">{JSON.stringify(schemaLogo)}</script>

      {slickslider === 'slick' &&  <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />}
      {slickslider === 'slick' && <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"/>}


      <script>
        {`!function(e){"use strict";function s(s){if(s){var t=e.documentElement;t.classList?t.classList.add("webp-version"):t.className+=" webp",window.sessionStorage.setItem("webpSupport",!0)}}!function(e){if(window.sessionStorage&&window.sessionStorage.getItem("webpSupport"))s(!0);else{var t=new Image;t.onload=t.onerror=function(){e(2===t.height)},t.src="data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wAiMwAgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA"}}(s)}(document);`}
      </script>
      {classbody && <body className={classbody} />}

    </Helmet>
  )
}

/* Function propTypes declaration */
Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  tLinkSlug: PropTypes.string,
  datastructured: PropTypes.string,
}
Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  tLinkSlug: null,
  datastructured: null
}

/* Query */
export const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle
        defaultDescription
        siteUrl
        siteLanguage
      }
    }
    defaultImage: file(relativePath: { eq: "og_myca.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
    defaultLogo: file(relativePath: { eq: "logo_myca.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
    }
  }
`

/* Export */
export default Seo
